export default function addRefParamToUrl(link: string, slotId: string): string {
  try {
    const params = new URLSearchParams([['nosto_ref', slotId]]);
    const separator = link.includes('?') ? '&' : '?';

    return [link, params.toString()].join(separator);
  } catch (error) {
    console.error('[NOSTO] [addRefParamToUrl]: Invalid URL provided:', error);
    return link;
  }
}
