export const parseStringToDate = (dateString: string): null | Date => {
  const ONE_SECOND = 1000;

  const lifetime = Number(dateString);
  if (isNaN(lifetime) || lifetime < 0) {
    return null;
  }

  const date = new Date(lifetime * ONE_SECOND);
  if (!date || isNaN(date.getTime())) {
    return null;
  }

  return date;
};
