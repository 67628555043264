



















import { computed, defineComponent } from '@nuxtjs/composition-api';

import AccountInfo from '~/diptyqueTheme/components/organisms/account/parts/AccountInfo.vue';
import AccountOrders from '~/diptyqueTheme/components/organisms/account/parts/AccountOrders.vue';
import AccountShippingAddresses from '~/diptyqueTheme/components/organisms/account/parts/AccountShippingAddresses.vue';
import AccountSubscriptions from '~/diptyqueTheme/components/organisms/account/parts/AccountSubscriptions.vue';
import Main from '~/diptyqueTheme/components/organisms/account/parts/Main.vue';
import VaimoSidebar from '~/diptyqueTheme/components/organisms/VaimoSidebar.vue';
import { useAccountStore } from '~/diptyqueTheme/stores/account';

export default defineComponent({
  /* eslint-disable vue/multi-word-component-names */
  name: 'Account',
  components: {
    VaimoSidebar,
    Main,
    AccountInfo,
    AccountShippingAddresses,
    AccountOrders,
    AccountSubscriptions
  },
  props: {
    inSideBar: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const accountStore = useAccountStore();
    const getCurrentScreen = computed(() => accountStore.currentScreen);

    const close = () => {
      accountStore.isVisible = false;
      accountStore.changeScreen('Main');
    };

    return {
      getCurrentScreen,
      accountStore,
      close
    };
  }
});
