import { computed, useContext } from '@nuxtjs/composition-api';
import { extend } from 'vee-validate';
import datePickerLangConfig from '~/diptyqueTheme/config/datePickerLangConfig';

extend('date-of-birth', {
  params: ['isUsCaStore'],
  validate: (value, { isUsCaStore }: { isUsCaStore: string }) => {
    if (isUsCaStore === 'false' || !value) {
      return true;
    }

    const [day, month, year] = value.split('/');

    if (!day || day < 1 || day > 31) {
      return 'Please enter a valid day';
    } else if (!month || month < 1 || month > 12) {
      return 'Please enter a valid month';
    } else if (!year) {
      return 'Year is required';
    } else if (year < 1900 || year > new Date().getFullYear()) {
      return 'Year must be between 1900 and current year';
    }

    return true;
  },
  message: (field, { value }) => value
});

export function useDatePicker() {
  const {
    app: { i18n }
  } = useContext();
  const getLocale = computed(() => i18n.localeProperties.code || i18n.defaultLocale);

  const getDatePickerLang = computed(() => {
    return datePickerLangConfig[getLocale.value] || 'en';
  });

  const getDateFormat = computed(() => (getLocale.value === 'ja_jp' ? 'YYYY/MM/DD' : 'DD/MM/YYYY'));

  return {
    getDatePickerLang,
    getDateFormat
  };
}
