



















import {
  defineComponent,
  ref
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoTooltip',
  props: {
    icon: {
      type: String,
      required: true
    },
    explanation: {
      type: String,
      required: true
    },
    explanationId: {
      type: String,
      required: true
    },
    iconSize: {
      type: Number,
      default: 12
    }
  },
  setup() {
    const isInfoBlockVisible = ref(false);
    const showHideInfoBlock = () => {
      isInfoBlockVisible.value = !isInfoBlockVisible.value;
    };
    const handleClickOutside = () => {
      if (isInfoBlockVisible.value) {
        isInfoBlockVisible.value = false;
      }
    };

    return {
      isInfoBlockVisible,
      showHideInfoBlock,
      handleClickOutside
    }
  }
});
