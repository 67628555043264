



































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import { useConfig } from '~/composables';
import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import VaimoCheckbox from '~/diptyqueTheme/components/molecules/VaimoCheckbox.vue';
import VaimoPasswordInput from '~/diptyqueTheme/components/organisms/VaimoPasswordInput.vue';
import {
  lsGet,
  lsRemove,
  lsSet
} from '~/diptyqueTheme/composable/useLocalStorage';
import { emailRegex } from '~/diptyqueTheme/helpers/regexes';
import { useUser } from '~/modules/customer/composables/useUser';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';

extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});
extend('required_email', {
  ...required,
  message: 'Please enter the email address.'
});

extend('required_pw', {
  ...required,
  message: 'Please enter the password.'
});

export default defineComponent({
  name: 'AccountLoginForm',
  components: {
    VaimoCheckbox,
    VaimoButton,
    LoyaltyPushRenderer: () =>
      import('templates/sections/LoyaltyPush/LoyaltyPushRenderer.vue'),
    VaimoPasswordInput,
    VaimoSocialLogin: () => import('organisms/VaimoSocialLogin.vue'),
    SfInput,
    ValidationProvider,
    ValidationObserver
  },
  emits: ['on-register', 'on-forgot-password'],
  setup(_, { emit }) {
    const {
      app: { $gtm }
    } = useContext();
    const { config } = useConfig();
    const isRememberMeChecked = ref(false);
    const { loading, login, isAuthenticated } = useUser();
    const route = useRoute();
    const { getLoginDetails } = useGoogleTagManager();
    const form = reactive({
      email: null,
      password: null
    });
    const selectRememberMe = () => {
      isRememberMeChecked.value = !isRememberMeChecked.value;
    };
    const register = () => {
      emit('on-register');
    };
    const onForgotPassword = () => {
      emit('on-forgot-password');
    };
    const onLogin = async (invalid) => {
      if (invalid) {
        return false;
      }
      await login({
        user: {
          email: form.email,
          password: form.password
        }
      });
      if (isAuthenticated.value) {
        $gtm.push(getLoginDetails(route.value, false));
      }
      lsRemove('login_form_data');
    };
    const setDataToLs = (key) => {
      let lsData = lsGet('login_form_data') || {};
      lsData = { ...lsData, [key]: form[key] };
      lsSet('login_form_data', lsData);
    };

    const setFormFromLs = () => {
      const lsData = lsGet('login_form_data');
      if (lsData && Object.keys(lsData)?.length) {
        for (let key in lsData) {
          form[key] = lsData[key];
        }
      }
    };

    const isSocialLoginEnabled = computed(
      () => config.value?.sociallogin_general_enabled
    );

    onMounted(() => {
      // Accessibility support: recreate focus area
      const sidebar = document.querySelector('aside.sf-sidebar__aside');
      focusTrap.componentUpdated(sidebar);
      setFormFromLs();
    });

    return {
      form,
      setDataToLs,
      register,
      onLogin,
      onForgotPassword,
      loading,
      selectRememberMe,
      isSocialLoginEnabled,
      isRememberMeChecked
    };
  }
});
