import { defineStore } from 'pinia';

export const useAccountStore = defineStore('accountStore', {
  state: () => ({
    isVisible: false,
    currentScreen: 'Main'
  }),
  actions: {
    changeScreen(screen) {
      this.currentScreen = screen;
    }
  }
});
