<template>
  <div class="help-block" :class="{ animate: isVisible }">
    <VaimoHeading
      heading-level="h3"
      heading-style="emphasized"
      :heading="$t('Need help?')"
      :aria-label="$t('Need help?')"
      :sub-heading="$t('We are here to assist you')"
      sub-heading-level="p"
      :animated-heading="false"
      :animated-description="false"
      class="help-block__heading"
    />
    <div ref="helpBlockContentEl" class="animate-content">
      <VaimoButton
        variant="default"
        class="help-block__button"
        role="link"
        :aria-label="$t('Contact Us') + ' ' + $t('Opens in new tab')"
        :link="getTermsOfServiceLinks.contact_us_link"
        link-behavior="open in new tab"
      >
        {{ $t('Contact Us') }}
      </VaimoButton>
    </div>
    <div class="help-block__content">
      <SfProperty
        v-for="(helpBlockContentItem, index) in helpBlockContent"
        :key="index"
        :name="helpBlockContentItem.name"
        :value="helpBlockContentItem.value"
      >
        <template #name>
          <div></div>
        </template>
      </SfProperty>
    </div>
    <a
      :href="getTermsOfServiceLinks.zendesk_link"
      class="underline"
      target="_blank"
      :aria-label="$t('Help Center') + ' ' + $t('Opens in new tab')"
      :title="$t('Help Center') + ' ' + $t('Opens in new tab')"
      rel="noreferrer nofollow"
    >
      {{ $t('Help Center') }}
    </a>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  useFetch,
  computed,
  useContext
} from '@nuxtjs/composition-api';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import contentfulEntriesIds from '~/diptyqueTheme/config/contentfulEntries';
import { SfProperty } from '@storefront-ui/vue';
import { useIntersectionObserver } from '@vueuse/core';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';
import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import { useStore } from '~/composables';

export default defineComponent({
  name: 'VaimoHelpBlock',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButton,
    SfProperty
  },
  setup() {
    const {
      query: helpBlockContentQuery,
      queryResponse: helpBlockContentEntry
    } = useContentfulGQL('byIdHelpBlock');
    const helpBlockContentEl = ref(null);
    const isVisible = ref(false);

    const {
      app: { i18n }
    } = useContext();

    useIntersectionObserver(
      helpBlockContentEl,
      ([{ isIntersecting, target }], observerElement) => {
        if (isIntersecting) {
          isVisible.value = true;
          observerElement.unobserve(target);
        }
      }
    );

    const getTermsOfServiceLinks = computed(() => {
      return termsOfServiceLinks[i18n.locale];
    });

    useFetch(async () => {
      if (!helpBlockContentEntry.value) {
        await helpBlockContentQuery('getMicroDataBunchById', {
          id: contentfulEntriesIds.HELP_BLOCK_MICRODATA_BUNCH_ID
        });
      }
    });
    return {
      helpBlockContent: computed(() => {
        const items =
          helpBlockContentEntry.value?.microdataBunch?.entriesCollection
            ?.items || [];

        return items.reduce((memo, cur) => {
          if (cur) {
            memo.push({
              id: cur.sys?.id,
              name: cur.key,
              value: cur.value
            });
          }
          return memo;
        }, []);
      }),
      helpBlockContentEl,
      isVisible,
      getTermsOfServiceLinks
    };
  }
});
</script>

<style lang="scss">
@import '@/diptyqueTheme/assets/styles/organisms/VaimoHelpBlock';
</style>
