















import {
  computed,
  defineComponent,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';

import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';

import { LOYALTY_PUSH_BANNER_TEXT, LOYALTY_PUSH_WITH_ICONS } from './consts';

export default defineComponent({
  name: 'LoyaltyPushRenderer',
  components: {
    SfLoader,
    LoyaltyPush: () => import('templates/sections/LoyaltyPush/index.vue')
  },
  props: {
    renderArea: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { app } = useContext();
    const id = app.context.route.value?.params?.slug || 'home';
    const { getFlexibleSectionByRenderArea, flexibleSectionEntries } =
      useContentfulPage(id);

    useFetch(async () => {
      await getFlexibleSectionByRenderArea(props.renderArea);
    });

    // Filter entries by the Loyalty Push types and sort by "order" property, handling undefined or null cases
    const DEFAULT_ORDER = -Infinity;
    const validTypes = [LOYALTY_PUSH_WITH_ICONS, LOYALTY_PUSH_BANNER_TEXT];
    const sortedLoyaltyPushEntries = computed(() => {
      if (!Array.isArray(flexibleSectionEntries.value)) {
        return [];
      }
      return flexibleSectionEntries.value
        .filter((item) => validTypes.includes(item.type))
        .sort(
          (a, b) => (a.order ?? DEFAULT_ORDER) - (b.order ?? DEFAULT_ORDER)
        );
    });

    return {
      sortedLoyaltyPushEntries
    };
  }
});
