

























import {
  defineComponent,
  ref,
  nextTick,
  useRoute,
  onMounted,
  computed,
  useContext
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppFooter',
  components: {
    VaimoOrnement: () => import('atoms/VaimoOrnement.vue'),
    VaimoEcomBenefits: () => import('organisms/VaimoEcomBenefits.vue'),
    VaimoFooterPleatPanels: () =>
      import('organisms/footer/PleatsPanel/VaimoFooterPleatPanels.vue'),
    VaimoHelpBlock: () => import('organisms/VaimoHelpBlock.vue'),
    VaimoNewsletterSubscription: () =>
      import('organisms/VaimoNewsletterSubscription.vue'),
    VaimoStoreSwitcher: () =>
      import('organisms/VaimoStoreSwitcher/VaimoStoreSwitcher.vue')
  },
  setup() {
    const route = useRoute();
    const routePath = computed(() => route.value.path);
    const isVisible = ref(false);
    const { i18n } = useContext();
    const locale = i18n.localeProperties?.code;

    const isCheckout = computed(() => {
      return routePath.value.includes('checkout');
    });

    const is404page = computed(() => {
      return route.value.matched.length === 0;
    })

    const isThankYouPage = computed(() => {
      return routePath.value.includes('checkout/thank-you');
    });

    onMounted(() => {
      nextTick(() => {
        isVisible.value = true;
      });
    });

    return {
      isVisible,
      isCheckout,
      isThankYouPage,
      is404page,
      locale
    };
  }
});
