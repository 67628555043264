









import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'AccountShippingAddresses',
  components: {
    AccountHeading: () => import('organisms/account/parts/AccountHeading.vue'),
    VaimoShippingAddresses: () => import('organisms/VaimoShippingAddresses.vue')
  }
});
