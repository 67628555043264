import { defineStore } from 'pinia';

interface TransitionStoreState {
  loadInstantly: boolean;
}

export const useTransitionStore = defineStore('transitionStore', {
  state: (): TransitionStoreState => ({
    loadInstantly: false
  }),

  actions: {
    setLoadInstantly(value: boolean): void {
      this.loadInstantly = value;
    }
  }
});
