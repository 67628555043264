






















import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@nuxtjs/composition-api';

import type { ProductInterface } from '~/composables';
import {
  hasUniqueCapacity,
  hasValidVariantName
} from '~/diptyqueTheme/helpers/product/attributes';
import {
  getProductCapacity,
  getProductVariantName,
  getRelatedProductsQnty
} from '~/diptyqueTheme/helpers/productGetters';
import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'VaimoProductVariant',
  props: {
    product: {
      type: Object as PropType<Product | ProductInterface>,
      required: true
    },
    displayRelatedProductsQnty: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props) {
    return {
      hasUniqueCapacity: computed(() => hasUniqueCapacity(props.product)),
      hasValidVariantName: computed(() => hasValidVariantName(props.product)),
      variantName: computed(() => getProductVariantName(props.product)),
      relatedProductsQnty: computed(() =>
        getRelatedProductsQnty(props.product)
      ),
      capacity: computed(() => getProductCapacity(props.product))
    };
  }
});
