import type { Cart as NostoCart, PushedCustomer as NostoCustomer } from '@nosto/types';

import { useNostoApi } from './useNostoApi';

/**
 * Sends active session to the Nosto.
 */
export function useNostoSession(cart: NostoCart, customer: NostoCustomer) {
  useNostoApi(async (api) => {
    const session = api.defaultSession();

    if (cart?.hcid) {
      session.setCart(cart);
    }

    if (customer) {
      session.setCustomer(customer);
    }

    return session.viewOther().update();
  }, 'SESSION');
}
