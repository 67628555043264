


















































































import { defineComponent } from '@nuxtjs/composition-api';
import { SfBadge, SfButton, SfLink } from '@storefront-ui/vue';
import VaimoIcon from 'atoms/VaimoIcon.vue';
import LazyHydrate from 'vue-lazy-hydration';

import Notification from '~/diptyqueTheme/components/Notification.vue';
import VaimoPromoBanner from '~/diptyqueTheme/components/organisms/VaimoPromoBanner.vue';

export default defineComponent({
  name: 'VaimoTopHeaderMobile',
  components: {
    VaimoIcon,
    LazyHydrate,
    SfLink,
    SfButton,
    SfBadge,
    VaimoPromoBanner,
    Notification
  },
  props: {
    isSimplified: {
      type: Boolean,
      default: false
    },
    cartProductsQuantity: {
      type: Number,
      default: 0
    }
  },
  emits: ['search-start', 'menu-toggle', 'account-toggle']
});
