import { useContext } from '@nuxtjs/composition-api';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';

interface FormattingOptions {
  minimumFractionDigits: number;
  maximumFractionDigits?: number;
}

export const getFormattingOptions = (price: number): FormattingOptions => {
  return price % 1 === 0
    ? {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }
    : {
        minimumFractionDigits: 2
      };
};

export const getFormattedLocale = (locale: string): string => {
  return locale ? locale.replace(/[!"$-/:-?[\]^_`{-~]/, '-') : '';
};

export const validateFraction = (price: number): string => {
  const { $fc } = useContext();
  const { selectedCurrency, selectedLocale } = useMagentoConfiguration();
  const locale = getFormattedLocale(selectedLocale.value);

  return $fc(price, locale, {
    style: 'currency',
    currency: selectedCurrency.value,
    ...getFormattingOptions(price)
  });
};

export const getCurrencySymbol = (): string => {
  const { app } = useContext();
  const currentLocaleISO: string = getFormattedLocale(app.i18n.locale);
  const currency: string = app?.$vsf?.$magento?.config?.state?.getCurrency();
  return (0)
    .toLocaleString(currentLocaleISO, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim();
};
