export default {
  fr_fr: 'https://try.abtasty.com/a382508fae72d647d77056140075ba6b.js',
  de_eu: 'https://try.abtasty.com/6f1fb219e521931e3ab39fdd1d407db8.js',
  it_eu: 'https://try.abtasty.com/6f1fb219e521931e3ab39fdd1d407db8.js',
  es_eu: 'https://try.abtasty.com/6f1fb219e521931e3ab39fdd1d407db8.js',
  fr_eu: 'https://try.abtasty.com/6f1fb219e521931e3ab39fdd1d407db8.js',
  en_eu: 'https://try.abtasty.com/6f1fb219e521931e3ab39fdd1d407db8.js',
  en_uk: 'https://try.abtasty.com/2cea081a7a7d86eda3872f1af9536858.js',
  en_us: 'https://try.abtasty.com/ed5ec7cc0f66b8302766180a9df3a790.js',
  ja_jp: 'https://try.abtasty.com/163050c252910bbf5cda44e0675bda9e.js'
};
