import type { ConversionItem as NostoOrderItem, OrderCustomer as NostoCustomer, WebsiteOrder as NostoOrder } from '@nosto/types';

import type { Customer, CustomerOrder, GuestOrder, OrderItem } from '~/modules/GraphQL/types';

/**
 * Encodes order ID with a prefix for Nosto integration.
 * @param id - The order ID to encode.
 * @returns The prefixed and encoded order number.
 */
function getOrderNumber(id: string): string {
  const orderNumberPrefix = 'M2_';
  try {
    const encodedId = Buffer.from(id, 'base64').toString();
    return orderNumberPrefix.concat(encodedId);
  } catch {
    return `${orderNumberPrefix}INVALID`;
  }
}

/**
 * Extracts customer data from the order or customer object.
 * @param order - The order object.
 * @param customer - Optional customer data.
 * @returns An object containing customer details.
 */
function getCustomerData(order: CustomerOrder | GuestOrder, customer?: Customer) {
  if (customer) {
    return {
      email: customer.email,
      first_name: customer.firstname,
      last_name: customer.lastname
    };
  }

  return {
    email: 'customer_email' in order ? order.customer_email : '',
    first_name: 'customer_firstname' in order ? order.customer_firstname : '',
    last_name: 'customer_lastname' in order ? order.customer_lastname : ''
  };
}

/**
 * Maps order info for the Nosto integration.
 * @param order - The order object.
 * @param customer - Optional customer data.
 * @returns A NostoCustomer object containing order and customer details.
 */
function mapOrderInfo(order: CustomerOrder | GuestOrder, customer?: Customer): NostoCustomer {
  return {
    country: order?.billing_address?.country_code ?? 'unknown',
    newsletter: String(customer?.is_subscribed ?? false),
    order_number: getOrderNumber(order?.id ?? ''),
    phone: order?.billing_address?.telephone ?? 'unknown',
    post_code: order?.billing_address?.postcode ?? 'unknown',
    type: 'order',
    ...getCustomerData(order, customer)
  };
}

/**
 * Maps individual order items to the Nosto format.
 * @param items - The array of order items to map.
 * @returns An array of mapped Nosto order items.
 */
function mapOrderItems(items: OrderItem[] = []): NostoOrderItem[] {
  return items.map((item) => ({
    name: item.product_name,
    price_currency_code: item.product_sale_price_incl_tax?.currency ?? 'unknown',
    product_id: String(item.product_id ?? 'unknown'),
    quantity: item.quantity_ordered ?? 1,
    sku_id: item.product_sku ?? 'unknown',
    unit_price: item.product_sale_price_incl_tax?.value ?? 0
  }));
}

/**
 * Maps an order object into the WebsiteOrder format for Nosto.
 * @param order - The order object to map from.
 * @param customer - Optional customer data.
 * @returns The mapped NostoOrder object.
 */
export default function mapCustomerOrder(order: CustomerOrder | GuestOrder, customer?: Customer): NostoOrder {
  return {
    external_order_ref: order?.number ?? 'unknown',
    info: mapOrderInfo(order, customer),
    items: mapOrderItems(order?.items ?? []),
    order_status: order?.status ?? 'unknown',
    payment_provider: order?.payment_methods?.[0]?.type ?? 'unknown'
  };
}
